import { createSlice } from "@reduxjs/toolkit";
import {
  AREA_COLORS,
  DEFAULT_LAYERS_LIST,
  DEFAULT_POINTS_LIST,
  LayerAlias,
  LayerInterface,
  PointTypeInterface
} from "constant";

interface LayerProviderInterface {
  data: {
    bounds?: number[];
    center?: number[];
    maxzoom?: number;
    rescale_min?: number;
    rescale_max?: number;
    task?: string;
    project?: string;
  };
}
export interface MapLayerInterface extends LayerInterface {
  params?: string;
  icon?: string;
  isActive?: boolean;
  providers?: LayerProviderInterface[];
  opacity?: number;
  fileUploadData?: {
    cancelExecutor?: () => void;
    progress?: number;
  };
  // TODO interface
  inference?: any;
}
export interface MapPointTypeInterface extends PointTypeInterface {
  isActive?: boolean;
}
export interface MapAriesInterface {
  radius: number;
  name: string;
  color: string;
  isActive: boolean;
  alias: string;
}

interface GeoDataInterface {
  type: string;
  coordinates: number[];
}

export interface MarkerInterface {
  color: string;
  created_at: string;
  geo_data: GeoDataInterface;
  id: string;
  layer: string;
  name: string;
  additional: {
    probability: number;
    object_type?: string;
  };
}

export interface PolygonInterface {
  coordinates: number[];
  id: string;
  alias: string;
  color: string;
  text: string;
  isActive?: boolean;
}

export interface MapStateInterface {
  isProjectLoading: boolean;
  isPolygonsLoading: boolean;
  isMarkersLoading: boolean;
  isMarkerDetailsLoading: boolean;
  isLayerCreateLoading: boolean;
  isMarkerCreateLoading: boolean;
  isMarkerDeleteLoading: boolean;
  title: string;
  id: string;
  layers: MapLayerInterface[];
  markers: MarkerInterface[];
  aries: MapAriesInterface[];
  polygons: MarkerInterface[];
  markerDetails: MarkerDetailsInterface;
  pointLayers: MapPointTypeInterface[];
  pointMarkers: MarkerInterface[];
}

export interface MarkerDetailsInterface {
  name: string;
  id: string;
  image: string;
  geo_data: {
    type: string;
    coordinates: number[];
  };
  isActive?: boolean;
}

export enum InferenceStatus {
  PREPARING = "preparing",
  PENDING = "pending",
  PROCESSING = "processing",
  DONE = "done",
  FAILED = "failed"
}

const initialState: MapStateInterface = {
  isProjectLoading: false,
  isPolygonsLoading: false,
  isMarkersLoading: false,
  isLayerCreateLoading: false,
  isMarkerDetailsLoading: false,
  isMarkerDeleteLoading: false,
  isMarkerCreateLoading: false,
  title: "",
  id: "",
  layers: DEFAULT_LAYERS_LIST,
  markers: [],
  aries: [],
  polygons: [],
  markerDetails: null,
  pointLayers: DEFAULT_POINTS_LIST,
  pointMarkers: []
};

const combineLayersList = (oldLayers: MapLayerInterface[], newLayers: any) => {
  let combinedArray = [...oldLayers, ...newLayers].reduce((acc, obj) => {
    acc[obj.alias] = {
      ...obj,
      isInit: acc[obj.alias] ? true : obj.isInit
    };
    return acc;
  }, {});
  combinedArray = Object.values(combinedArray);
  return combinedArray;
};

export const mainSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    fetchProjectRequest: (state) => {
      state.isProjectLoading = true;
    },
    fetchProjectSuccess: (state, { payload }) => {
      state.isProjectLoading = false;
      state.title = payload.name;
      state.id = payload.id;

      const resLayers = payload?.layers ?? [];

      const combinedArray = combineLayersList(state.layers, resLayers);

      state.layers = combinedArray;
    },
    fetchProjectError: (state) => {
      state.isProjectLoading = false;
    },

    startInferenceRequest: (state, { payload }) => {
      const { alias } = payload.params;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { isInferenceLoading: true }
            }
          : layer
      );
    },
    startInferenceSuccess: (state, { payload }) => {
      const { alias } = payload.params;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, isInferenceLoading: false }
            }
          : layer
      );
    },
    startInferenceError: (state, { payload }) => {
      const { alias } = payload.params;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, isInferenceLoading: false }
            }
          : layer
      );
    },

    setInferenceFile: (state, { payload }) => {
      const { alias, values } = payload;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, inferenceFile: values }
            }
          : layer
      );
    },

    startInferenceWithFileRequest: (state, { payload }) => {
      const { alias } = payload.params;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { isInferenceLoading: true }
            }
          : layer
      );
    },
    startInferenceWithFileSuccess: (state, { payload }) => {
      const { alias } = payload;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, isInferenceLoading: false }
            }
          : layer
      );
    },
    startInferenceWithFileError: (state, { payload }) => {
      const { alias } = payload;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, isInferenceLoading: false }
            }
          : layer
      );
    },

    checkInferenceStatusRequest: (state, { payload }) => {
      const { params } = payload;
      const { alias } = params;

      state.layers = state.layers.map((layer) => {
        return layer.alias === alias
          ? {
              ...layer,
              inference: {
                ...layer.inference,
                isInferenceStatusLoading: true
              }
            }
          : layer;
      });
    },
    checkInferenceStatusSuccess: (state, { payload }) => {
      const { params, data } = payload;
      const { alias } = params;

      state.layers = state.layers.map((layer) => {
        return layer.alias === alias ? { ...layer, inference: data } : layer;
      });
    },
    checkInferenceStatusDone: (state, { payload }) => {
      const { alias, showResult } = payload;

      state.layers = state.layers.map((layer) => {
        return layer.alias === alias
          ? {
              ...layer,
              inference: {
                ...layer.inference,
                showResult,
                isInferenceStatusLoading: false
              }
            }
          : layer;
      });
    },
    checkInferenceStatusError: (state, { payload }) => {
      const { params, showResult } = payload;
      const { alias } = params;

      state.layers = state.layers.map((layer) => {
        return layer.alias === alias
          ? {
              ...layer,
              inference: {
                ...layer.inference,
                showResult,
                isInferenceStatusLoading: false
              }
            }
          : layer;
      });
    },
    // fetchLayersRequest: (state) => {
    //   state.isLoading = true;
    // },
    // fetchLayersSuccess: (state, { payload }) => {
    //   state.isLoading = false;
    //   state.layers = payload.map((layer) => {
    //     return { ...layer, isActive: layer.is_default };
    //   });
    // },
    // fetchLayersError: (state) => {
    //   state.isLoading = false;
    // },
    fetchAriesRequest: (state) => {
      state.isProjectLoading = true;
    },
    fetchAriesSuccess: (state, {}) => {
      //TODO colors from back-end
      state.isProjectLoading = false;
      state.aries = AREA_COLORS;
    },
    fetchAriesError: (state) => {
      state.isProjectLoading = false;
    },

    fetchMarkersRequest: (state) => {
      state.isMarkersLoading = true;
    },
    fetchMarkersSuccess: (state, { payload }) => {
      const { data, params } = payload;

      state.isMarkersLoading = false;

      const newMarkers =
        data.points?.map((marker) => {
          return {
            ...marker,
            name: params.alias === LayerAlias.THERMAL ? undefined : marker.name,
            layer: params.alias,
            color: state.layers.find((layer) => {
              return layer.alias === params.alias;
            }).color
          };
        }) ?? [];

      if (params.alias === LayerAlias.MANUAL) {
        state.pointMarkers = [...state.pointMarkers, ...newMarkers];
      } else {
        state.markers = [...state.markers, ...newMarkers];
        state.polygons = state.markers;
      }
    },
    fetchMarkersError: (state) => {
      state.isMarkersLoading = false;
    },

    fetchPolygonsRequest: (state) => {
      state.isPolygonsLoading = true;
    },
    fetchPolygonsSuccess: (state, { payload }) => {
      state.isPolygonsLoading = false;
      state.polygons = payload;
    },
    fetchPolygonsError: (state) => {
      state.isPolygonsLoading = false;
    },

    fetchMarkerDetailsRequest: (state, { payload }) => {
      state.isMarkerDetailsLoading = true;
      state.markerDetails = {
        ...state.markerDetails,
        isActive: payload.fields.isActive
      };
    },
    fetchMarkerDetailsSuccess: (state, { payload }) => {
      state.isMarkerDetailsLoading = false;
      state.markerDetails = { ...state.markerDetails, ...payload };
    },
    fetchMarkerDetailsError: (state) => {
      state.isMarkerDetailsLoading = false;
    },
    clearMarkerDetails: (state) => {
      state.markerDetails = { ...state.markerDetails, isActive: false };
    },

    createMarkerRequest: (state) => {
      state.isMarkerCreateLoading = true;
    },
    createMarkerSuccess: (state, { payload }) => {
      state.pointMarkers = [
        ...state.pointMarkers,
        { ...payload, layer: LayerAlias.MANUAL }
      ];

      state.isMarkerCreateLoading = false;
    },
    createMarkerError: (state) => {
      state.isMarkerCreateLoading = false;
    },

    updateMarkerRequest: (state) => {
      state.isMarkerCreateLoading = true;
    },
    updateMarkerSuccess: (state, { payload }) => {
      state.pointMarkers = state.pointMarkers.map((marker) =>
        String(marker.id) !== String(payload.id)
          ? marker
          : { ...marker, ...payload }
      );

      state.isMarkerCreateLoading = false;
    },
    updateMarkerError: (state) => {
      state.isMarkerCreateLoading = false;
    },

    deleteMarkerRequest: (state) => {
      state.isMarkerDeleteLoading = true;
    },
    deleteMarkerSuccess: (state, { payload }) => {
      state.isMarkerDeleteLoading = false;
      state.markerDetails = null;

      const newMarkers = state.markers.filter(
        (marker) => String(marker.id) !== String(payload.message)
      );
      state.markers = newMarkers;

      const newPointMarkers = state.pointMarkers.filter(
        (marker) => String(marker.id) !== String(payload.message)
      );
      state.pointMarkers = newPointMarkers;
    },
    deleteMarkerError: (state) => {
      state.isMarkerDeleteLoading = false;
    },

    setLayerIsActive: (state, { payload }) => {
      state.layers = state.layers.map((item) =>
        item.alias === payload.params.alias
          ? { ...item, isActive: payload.fields?.isActive }
          : item
      );
    },
    toggleAriaIsActive: (state, { payload }) => {
      state.aries = state.aries.map((item) =>
        item.alias === payload
          ? { ...item, isActive: payload.isActive ?? !item.isActive }
          : item
      );
    },

    createLayerRequest: (state) => {
      state.isLayerCreateLoading = true;
    },
    createLayerSuccess: (state, { payload }) => {
      const combinedArray = combineLayersList(state.layers, [payload]);

      state.layers = combinedArray;

      state.isLayerCreateLoading = false;
    },
    createLayerError: (state) => {
      state.isLayerCreateLoading = false;
    },
    setActiveLayerPoints: (state, { payload }) => {
      state.pointLayers = state.pointLayers.map((item) =>
        item.value === payload.value
          ? { ...item, isActive: payload.isActive }
          : item
      );
    },
    setUploadLayerFileCancelEvent: (state, { payload }) => {
      const { cancelExecutor, params } = payload;

      state.layers = state.layers.map((layer) =>
        layer.alias === params.alias
          ? {
              ...layer,
              fileUploadData: { ...layer.fileUploadData, cancelExecutor }
            }
          : layer
      );
    },
    setUploadLayerFileProgress: (state, { payload }) => {
      const { progress, params } = payload;

      state.layers = state.layers.map((layer) =>
        layer.alias === params.alias
          ? {
              ...layer,
              fileUploadData: { ...layer.fileUploadData, progress }
            }
          : layer
      );
    },

    setLayerParams: (state, { payload }) => {
      state.layers = state.layers.map((item) => {
        return item.alias === payload.alias
          ? { ...item, params: payload.params }
          : item;
      });
    },
    setLayerOpacity: (state, { payload }) => {
      state.layers = state.layers.map((item) => {
        return item.alias === payload.alias
          ? { ...item, opacity: payload.opacity }
          : item;
      });
    },

    clearState: () => {
      return { ...initialState };
    }
  }
});

export const { actions, reducer } = mainSlice;
