import L, { DivIcon, LatLngExpression, PointExpression } from "leaflet";
import { useCallback } from "react";
import { renderToString } from "react-dom/server";
import { Marker, useMap } from "react-leaflet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import features from "features";
import { MapLayerInterface, MarkerInterface } from "scenes/MapPage/ducks";
import usePointHandler from "scenes/MapPage/usePointHandler";

import { LayerAlias } from "constant";
import MapMarkerIcon from "../MapMarkerIcon/MapMarkerIcon";
import MapPointIcon from "../MapPointIcon/MapPointIcon";
import MapSensorFusionMarkerIcon from "../MapSensorFusionMarkerIcon/MapSensorFusionMarkerIcon";

interface MapMarkersInterface {
  markers: MarkerInterface[];
  activeMarkerId: string;
  opacityByLayerList: MapLayerInterface[];
}

const MapMarkers = ({
  markers,
  activeMarkerId,
  opacityByLayerList
}: MapMarkersInterface) => {
  const dispatch = useDispatch();
  const map = useMap();
  const { id: projectId } = useParams();
  const { viewPointDetails } = usePointHandler();

  const getMarkerIcon = (marker) => {
    const isPoint = !!marker.additional.object_type;
    const iconSize: PointExpression = isPoint ? [25, 30] : [2, 17];
    const iconAnchor: PointExpression = isPoint ? [0, 30] : [1, 18];

    return new DivIcon({
      html: renderToString(
        marker.layer === LayerAlias.SENSOR_FUSION ? (
          <MapSensorFusionMarkerIcon
            color={marker.color}
            sensorFusion={marker.additional.sensor_fusion}
            probability={marker.additional.probability}
            markerName={marker?.name}
            isActive={activeMarkerId === marker.id}
          />
        ) : marker.additional.object_type ? (
          <MapPointIcon type={marker.additional.object_type} />
        ) : (
          <MapMarkerIcon
            color={marker.color}
            probability={marker.additional.probability}
            markerName={marker?.name}
            isActive={activeMarkerId === marker.id}
          />
        )
      ),
      className: "marker-reset",
      iconSize,
      iconAnchor
    });
  };

  const shiftMapLeft = useCallback(
    (coordinates) => {
      const detailsWindow = document.querySelector("#pointDetails");

      const detailsWindowRect = detailsWindow.getBoundingClientRect();

      const distance = detailsWindowRect.right + window.scrollX;

      const screenWidthInPixels = distance / 2;

      const centerPoint = map.latLngToContainerPoint(coordinates);
      const newPoint = L.point([
        centerPoint.x - screenWidthInPixels,
        centerPoint.y
      ]);
      const newLatLng = map.containerPointToLatLng(newPoint);

      map.panTo(newLatLng);
    },
    [map]
  );

  const onMarkerClick = useCallback(
    (marker) => () => {
      const isPoint = !!marker.additional.object_type;

      dispatch(
        features.map.actions.fetchMarkerDetailsRequest({
          params: { id: marker.id, projectId },
          fields: { isActive: !isPoint },
          onSuccess: (res) => {
            isPoint
              ? viewPointDetails(res)
              : shiftMapLeft(marker.geo_data.coordinates);
          }
        })
      );
    },
    [dispatch, projectId, shiftMapLeft, viewPointDetails]
  );

  return (
    <>
      {markers.map((marker: MarkerInterface, index: number) => (
        <Marker
          position={marker.geo_data.coordinates as LatLngExpression}
          icon={getMarkerIcon(marker)}
          opacity={
            opacityByLayerList.find((layer) => layer.alias === marker.layer)
              ?.opacity / 100
          }
          eventHandlers={{
            click: onMarkerClick(marker)
          }}
          key={`marker-${index}`}
        />
      ))}
    </>
  );
};
export default MapMarkers;
