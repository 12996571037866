import L, { LatLngTuple } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  GEO_VISION_API_URL,
  KYIV_COORDINATES,
  LayerAlias,
  STORAGE_URL
} from "constant";
import features from "features";
import { getToken } from "helpers/jwt";
import { RootStateInterface } from "reducer";
import { MapStateInterface } from "../ducks";

import ZoomBtns from "components/ZoomBtns/ZoomBtns";
import MapLayers from "../components/MapLayers/MapLayers";
import MapMarkerDetails from "../components/MapMarkerDetails/MapMarkerDetails";
import MapMarkers from "../components/MapMarkers/MapMarkers";
import MapPolygons from "../components/MapPolygons/MapPolygons";
import MapProjectName from "../components/MapProjectName/MapProjectName";
import MapSearchPoint from "../components/MapSearchPoint/MapSearchPoint";

import styles from "./map-page.module.scss";

const MapPage = () => {
  const mapRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [perimeterBounds, setPerimeterBounds] = useState<LatLngTuple[] | null>(
    null
  );

  const {
    title,
    layers,
    aries,
    markers,
    markerDetails,
    pointLayers,
    pointMarkers,
    isProjectLoading,
    isMarkerDetailsLoading,
    isMarkerDeleteLoading,
    isLayerCreateLoading,
    isMarkersLoading,
    isMarkerCreateLoading
  } = useSelector<RootStateInterface, MapStateInterface>((state) => state.map);

  const isProcessing = useMemo(
    () =>
      isProjectLoading ||
      isLayerCreateLoading ||
      isMarkersLoading ||
      isMarkerCreateLoading,
    [
      isLayerCreateLoading,
      isMarkersLoading,
      isProjectLoading,
      isMarkerCreateLoading
    ]
  );
  const selectedLayers = useMemo(
    () => layers.filter((layer) => layer.isActive),
    [layers]
  );
  const selectedAries = useMemo(
    () => aries.filter((aria) => aria.isActive),
    [aries]
  );
  const selectedMarkers = useMemo(
    () =>
      markers.filter((marker) =>
        selectedLayers.find(
          (selectedLayer) => selectedLayer.alias === marker.layer
        )
      ),
    [selectedLayers, markers]
  );
  const availablePointLayers = useMemo(
    () =>
      pointLayers.filter((layer) =>
        pointMarkers.find((item) => item.additional.object_type === layer.value)
      ),
    [pointLayers, pointMarkers]
  );
  const selectedPointLayers = useMemo(
    () => pointLayers.filter((layer) => layer.isActive),
    [pointLayers]
  );
  const selectedPointMarkers = useMemo(
    () =>
      pointMarkers.filter((marker) =>
        selectedPointLayers.find(
          (selectedLayer) =>
            selectedLayer.value === marker.additional.object_type
        )
      ),
    [selectedPointLayers, pointMarkers]
  );
  const activeMarker = useMemo(
    () => selectedMarkers.find((marker) => marker.id === markerDetails?.id),
    [markerDetails?.id, selectedMarkers]
  );
  const thermalMarkers = useMemo(() => {
    return selectedMarkers.filter((item) => item.layer === LayerAlias.THERMAL);
  }, [selectedMarkers]);
  const isFileUploadingProgress = useMemo(() => {
    if (isLayerCreateLoading) {
      return layers.find((layer) => layer.fileUploadData?.progress)
        ?.fileUploadData?.progress;
    }
  }, [isLayerCreateLoading, layers]);

  const defaultActiveLayer = useMemo(() => {
    return layers?.find((item) => item.isInit);
  }, [layers]);

  const mapCenter = useMemo(() => {
    const center = defaultActiveLayer?.providers?.[0]?.data?.center;
    return center ? { lng: center[0], lat: center[1] } : null;
  }, [defaultActiveLayer]);

  const mapZoom = useMemo(() => {
    const zoom = defaultActiveLayer?.providers?.[0]?.data?.center?.[2];
    const maxNativeZoom = defaultActiveLayer?.providers?.[0]?.data?.maxzoom;

    return {
      zoom: zoom > 0 ? zoom : 9,
      maxNativeZoom: maxNativeZoom > 0 ? maxNativeZoom : 26
    };
  }, [defaultActiveLayer]);

  useEffect(() => {
    dispatch(features.map.actions.fetchProjectRequest({ params: { id } }));
    dispatch(features.map.actions.fetchAriesRequest());
  }, [dispatch, id]);

  // useEffect(() => {
  //   if (inferenceStatus) {
  //     const { status } = inferenceStatus;

  //     if (!isInferenceStatusLoading) {
  //       const closeKey = "SUCCESS_INFERENCE";

  //       if (inferenceStatus.showSuccessModal) {
  //         dispatch(
  //           features.modal.actions.showModal({
  //             modalType: "SUCCESS",
  //             modalProps: {
  //               title: "Complete",
  //               description: "Success",
  //               acceptButtonAction: () =>
  //                 dispatch(features.modal.actions.hideModal(closeKey)),
  //               isWithProgress: true
  //             },
  //             closeKey
  //           })
  //         );
  //       }
  //     }

  //     if (
  //       status === InferenceStatus.PREPARING ||
  //       status === InferenceStatus.PENDING
  //     ) {
  //       dispatch(
  //         features.modal.actions.showModal({
  //           modalType: "PROGRESS",
  //           modalProps: {
  //             title: "Analyzing",
  //             description: inferenceStatus.message,
  //             isDisableClose: true,
  //             progressValue: inferenceStatus.completion_percentage,
  //             modalActionButton: {
  //               label: "Next",
  //               isDisable: true
  //             }
  //           }
  //         })
  //       );
  //     }
  //   }
  // }, [dispatch, id, inferenceStatus, isInferenceStatusLoading]);

  useEffect(() => {
    if (isFileUploadingProgress) {
      dispatch(
        features.modal.actions.showModal({
          modalType: "PROGRESS",
          modalProps: {
            title: "Analyzing",
            description: "Uploading file",
            isDisableClose: true,
            progressValue: isFileUploadingProgress,
            modalActionButton: {
              label: "Next",
              isDisable: true
            }
          }
        })
      );
    }
  }, [dispatch, isFileUploadingProgress]);

  useEffect(() => {
    if (isProcessing)
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: "Processing",
            loading: true,
            isDisableClose: true
          }
        })
      );
    else dispatch(features.modal.actions.hideModal());
  }, [dispatch, isProcessing]);

  useEffect(() => {
    return () => {
      dispatch(features.modal.actions.hideModal());
      dispatch(features.map.actions.clearState());
    };
  }, [dispatch, location.key]);

  const setView = (...args) => {
    if (mapRef.current) {
      mapRef.current.setView(...args);
    }
  };

  const createPerimeter = (
    point1: LatLngTuple,
    point2: LatLngTuple,
    point3: LatLngTuple,
    point4: LatLngTuple
  ) => {
    const bounds: LatLngTuple[] = [point1, point2, point3, point4];
    setPerimeterBounds(bounds);

    const avgLat =
      bounds.reduce((sum, item) => sum + item[0], 0) / bounds.length;
    const avgLng =
      bounds.reduce((sum, item) => sum + item[1], 0) / bounds.length;

    mapRef.current.setView({ lat: avgLat, lng: avgLng }, 18);
  };

  const removePerimeter = () => {
    setPerimeterBounds(null);
  };

  useEffect(() => {
    (window as any).createPerimeter = createPerimeter;
    (window as any).removePerimeter = removePerimeter;
  }, []);

  const magneticLayer = useMemo(() => {
    return selectedLayers.find((item) => item.alias === LayerAlias.MAGNETIC);
  }, [selectedLayers]);

  return (
    <div className={styles["map-page"]}>
      {title && (
        <MapProjectName name={title} className={styles["project-title"]} />
      )}
      <MapLayers
        layers={layers}
        aries={aries}
        setView={setView}
        points={availablePointLayers}
      />
      {title && (
        <MapContainer
          zoom={mapZoom.zoom}
          zoomControl={false}
          center={mapCenter ? mapCenter : KYIV_COORDINATES}
          className={styles["map"]}
          pmIgnore={false}
          maxZoom={28}
          ref={mapRef}
          preferCanvas={true}
        >
          <TileLayer
            url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            maxNativeZoom={20}
            maxZoom={30}
          />
          {selectedLayers.map((item) => (
            <TileLayer
              accessToken={getToken()}
              url={`${GEO_VISION_API_URL}/projects/${id}/layers/${
                item.alias === LayerAlias.SENSOR_FUSION
                  ? LayerAlias.RGB
                  : item.alias
              }/tiles/{z}/{x}/{y}${item.params ?? ""}`}
              bounds={
                item?.providers?.[0]?.data?.bounds
                  ? new L.LatLngBounds([
                      [
                        item?.providers?.[0]?.data?.bounds[1],
                        item?.providers?.[0]?.data?.bounds[0]
                      ],
                      [
                        item?.providers?.[0]?.data?.bounds[3],
                        item?.providers?.[0]?.data?.bounds[2]
                      ]
                    ])
                  : null
              }
              maxNativeZoom={mapZoom.maxNativeZoom}
              maxZoom={30}
              opacity={(item.opacity ?? 100) / 100}
              detectRetina={true}
              key={`tile-${item.alias}`}
            />
          ))}
          {(selectedMarkers.length > 0 || selectedPointMarkers.length > 0) && (
            <MapMarkers
              markers={[...selectedMarkers, ...selectedPointMarkers]}
              activeMarkerId={markerDetails?.id}
              opacityByLayerList={selectedLayers.filter(
                (layer) => layer.alias === LayerAlias.THERMAL
              )}
            />
          )}
          {magneticLayer && (
            <TileLayer
              url={`${STORAGE_URL}/mapbuilder/project/${id}/mag/tiles/{z}/{x}/{y}.png`}
              maxNativeZoom={26}
              maxZoom={30}
              opacity={(magneticLayer?.opacity ?? 100) / 100}
            />
          )}
          {selectedAries?.length > 0 && selectedMarkers.length > 0 && (
            <MapPolygons aries={selectedAries} markers={selectedMarkers} />
          )}
          {thermalMarkers.length > 0 && (
            <MapPolygons
              aries={[
                {
                  name: "Thermal Area",
                  color: selectedLayers.find(
                    (layer) => layer.alias === LayerAlias.THERMAL
                  ).color,
                  isActive: true,
                  alias: LayerAlias.THERMAL,
                  radius: 0.2
                }
              ]}
              markers={thermalMarkers}
            />
          )}
          <ZoomBtns
            zoomIn={() => mapRef.current.zoomIn()}
            zoomOut={() => mapRef.current.zoomOut()}
          />
          <MapSearchPoint />
          {perimeterBounds && (
            <Polygon positions={perimeterBounds} color="blue" />
          )}
        </MapContainer>
      )}
      <MapMarkerDetails
        activeMarker={activeMarker}
        markerDetails={markerDetails}
        isMarkerDetailsLoading={isMarkerDetailsLoading}
        isMarkerDeleteLoading={isMarkerDeleteLoading}
        projectId={id}
        layers={layers}
      />
    </div>
  );
};

export default MapPage;
