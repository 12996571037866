import { useMemo } from "react";

import { InferenceStatus } from "scenes/MapPage/ducks";

import CommonButton from "components/buttons/CommonButton/CommonButton";

import DownloadIcon from "assets/icons/download.svg";
import PlayIcon from "assets/icons/play.svg";

import styles from "./map-action-btns.module.scss";

interface MapActionBtnsInterface {
  icon?: string;
  inferenceIcon?: string;
  action?: () => void;
  isLayerInit?: boolean;
  inferenceAction?: () => void;
  inference?: any;
  isFileRequired?: boolean;
}

const MapActionBtns = ({
  icon,
  inferenceIcon,
  action,
  isLayerInit,
  inferenceAction,
  inference,
  isFileRequired
}: MapActionBtnsInterface) => {
  const isInferenceProgress = useMemo(() => {
    return (
      inference?.status === InferenceStatus.PENDING ||
      inference?.status === InferenceStatus.PREPARING ||
      inference?.status === InferenceStatus.PROCESSING ||
      inference?.isInferenceStatusLoading ||
      inference?.isInferenceLoading
    );
  }, [inference]);
  const isDisabledInference = useMemo(() => {
    if (isFileRequired)
      return !!!inference?.inferenceFile || isInferenceProgress;
    return isInferenceProgress || !isLayerInit;
  }, [
    inference?.inferenceFile,
    isFileRequired,
    isInferenceProgress,
    isLayerInit
  ]);

  return (
    <div className={styles["map-action-buttons-container"]}>
      <div className={styles["map-action-buttons-container__row"]}>
        {action && (
          <CommonButton
            type="button"
            icon={icon ?? DownloadIcon}
            onClick={action}
            disabled={(isLayerInit && !isFileRequired) || isInferenceProgress}
          >
            {"Add"}
          </CommonButton>
        )}
        {inferenceAction && (
          <CommonButton
            type="button"
            onClick={inferenceAction}
            icon={inferenceIcon ?? PlayIcon}
            isLoading={isInferenceProgress}
            disabled={isDisabledInference}
          >
            {isInferenceProgress ? "Inferencing..." : "Start"}
          </CommonButton>
        )}
      </div>
    </div>
  );
};

export default MapActionBtns;
