import {
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
  fork,
  cancel
} from "redux-saga/effects";

import { requestMiddleware, uploadFileMiddleware } from "helpers/api";
import {
  getMarkers,
  getMarkerDetails,
  getAries,
  getPolygons,
  removeMarker,
  addLayerLink,
  runInference,
  addLayerFile,
  getInferenceStatus,
  addMarker,
  putMarker,
  runInferenceFileUpload
} from "services/api/map";
import { LayerAlias, MULTISPECTRAL_OPTIONS } from "constant";
import { getProject } from "services/api/project";
import { actions, InferenceStatus } from "./ducks";

const CHECK_STATUS_DELAY_MS = 5000;

function* fetchProject({ payload }) {
  const request = getProject;

  const {
    fetchProjectSuccess: setSuccess,
    fetchProjectError: setError,
    setLayerIsActive,
    checkInferenceStatusRequest
  } = actions;

  function* onSuccess(res) {
    const defaultLayer = res.layers?.[0];
    const manualLayer = res.layers?.find(
      (layer) => layer.alias === LayerAlias.MANUAL
    );

    if (defaultLayer) {
      const alias = defaultLayer.alias;
      const payload = { params: { alias } };

      yield put(
        yield setLayerIsActive({ ...payload, fields: { isActive: true } })
      );
    }

    if (manualLayer) {
      const alias = manualLayer.alias;
      const payload = { params: { alias } };

      yield put(
        yield setLayerIsActive({ ...payload, fields: { isActive: true } })
      );
    }

    if (res.layers?.length)
      for (const element of res.layers) {
        const { alias } = element;

        yield put(
          yield checkInferenceStatusRequest({
            ...payload,
            params: { ...payload.params, alias }
          })
        );
      }
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess
  });
}

function* startInference({ payload }) {
  const request = runInference;
  const { startInferenceSuccess: setSuccess, startInferenceError: setError } =
    actions;
  let task;
  function* onError() {
    if (task) {
      yield cancel(task);
    }
  }
  function* onSuccess() {
    task = yield fork(delayInferenceStatusCheck, { payload });
  }
  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onError,
    onSuccess,
    isReturnRequestPayload: true
  });
}
function* startInferenceWithFile({ payload }) {
  const request = runInferenceFileUpload;

  const {
    startInferenceSuccess: setSuccess,
    startInferenceError: setError,
    setUploadLayerFileCancelEvent: getUploadCancelExecutor,
    setUploadLayerFileProgress: setUploadProgress
  } = actions;

  let task;
  function* onError() {
    if (task) {
      yield cancel(task);
    }
  }
  function* onSuccess() {
    task = yield fork(delayInferenceStatusCheck, { payload });
  }

  yield uploadFileMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    getUploadCancelExecutor,
    setUploadProgress,
    onError,
    onSuccess,
    isReturnRequestPayload: true
  });
}

function* delayInferenceStatusCheck({ payload }) {
  const { checkInferenceStatusRequest } = actions;

  yield put(yield checkInferenceStatusRequest(payload));
}

function* watchCheckInferenceStatus({ payload }) {
  const { clearState } = actions;

  const task = yield fork(checkInferenceStatus, { payload });

  yield takeLatest(clearState, function* () {
    yield cancel(task);
  });
}

function* checkInferenceStatus({ payload }) {
  const request = getInferenceStatus;
  const {
    checkInferenceStatusSuccess: setSuccess,
    checkInferenceStatusError: setError,
    checkInferenceStatusDone,
    setLayerIsActive
  } = actions;

  let reqCount = 0;
  let isDone = false;

  function* onSuccess(res) {
    const { data } = res;

    if (data?.status) {
      const { status } = data;

      const isSuccess = InferenceStatus.DONE === status;
      const isError = InferenceStatus.FAILED === status;
      isDone = isSuccess || isError;

      if (isDone) {
        yield put(
          yield checkInferenceStatusDone({
            showResult: isDone && reqCount > 1,
            alias: payload.params.alias
          })
        );
      }
      if (isSuccess)
        yield put(
          yield setLayerIsActive({ ...payload, fields: { isActive: true } })
        );
    }
  }

  const onError = () => {
    isDone = true;
  };

  while (!isDone) {
    reqCount = reqCount + 1;

    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      onSuccess,
      onError,
      isReturnRequestPayload: true
    });

    yield delay(CHECK_STATUS_DELAY_MS);
  }
}

// function* fetchLayers({ payload }) {
//   const request = getLayers;

//   const { fetchLayersSuccess: setSuccess, fetchLayersError: setError } =
//     actions;

//   function* onSuccess(res) {
//     const alias = res.find((item) => item.is_default).alias;
//     yield fetchMarkers({ payload: { params: { alias } } });
//   }

//   yield requestMiddleware({
//     ...payload,
//     request,
//     setSuccess,
//     setError,
//     onSuccess
//   });
// }

function* fetchAries({ payload }) {
  const request = getAries;

  const { fetchAriesSuccess: setSuccess, fetchAriesError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* handleSetLayerIsActive({ payload }) {
  const { id, markers, layers } = yield select((state) => state.map);
  const { alias } = payload.params;
  const { fetchMarkersRequest } = actions;

  if (alias === LayerAlias.MULTISPECTRAL) {
    const { setLayerParams } = actions;

    const layerData = layers.find((layer) => layer.alias === alias);

    const params = MULTISPECTRAL_OPTIONS(
      layerData?.providers?.[0]?.data?.rescale_min,
      layerData?.providers?.[0]?.data?.rescale_max
    )[0].params;

    yield put(yield setLayerParams({ params, alias }));
  }

  const isMarkersLoaded = markers.find((marker) => marker.layer === alias);

  if (!isMarkersLoaded)
    yield put(
      yield fetchMarkersRequest({
        ...payload,
        params: { ...payload.params, id }
      })
    );
}

function* fetchMarkers({ payload }) {
  const request = getMarkers;

  const { fetchMarkersSuccess: setSuccess, fetchMarkersError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}

function* fetchPolygons({ payload }) {
  const request = getPolygons;

  const { fetchPolygonsSuccess: setSuccess, fetchPolygonsError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchMarkerDetails({ payload }) {
  const request = getMarkerDetails;

  const {
    fetchMarkerDetailsSuccess: setSuccess,
    fetchMarkerDetailsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* createLayer({ payload }) {
  const {
    createLayerSuccess: setSuccess,
    createLayerError: setError,
    setUploadLayerFileCancelEvent: getUploadCancelExecutor,
    setUploadLayerFileProgress: setUploadProgress,
    setLayerIsActive
  } = actions;

  function* onSuccess(res) {
    yield payload.onSuccess(res);
    yield put(
      yield setLayerIsActive({ ...payload, fields: { isActive: true } })
    );
  }

  if (payload.fields?.file) {
    const request = addLayerFile;

    yield uploadFileMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      onSuccess,
      setUploadProgress,
      getUploadCancelExecutor
    });
  } else {
    const request = addLayerLink;

    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      onSuccess
    });
  }
}

function* createMarker({ payload }) {
  const request = addMarker;
  const {
    createMarkerSuccess: setSuccess,
    createMarkerError: setError,
    createLayerRequest,
    setActiveLayerPoints
  } = actions;

  const { layers } = yield select((state) => state.map);
  const isManualLayer = layers.find(
    (layer) => layer.alias === LayerAlias.MANUAL
  )?.isInit;

  function* onSuccess() {
    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError
    });
  }

  if (!isManualLayer) {
    yield put(
      yield createLayerRequest({
        ...payload,
        params: { id: payload.params.projectId, alias: LayerAlias.MANUAL },
        fields: undefined,
        onSuccess
      })
    );
  } else {
    yield onSuccess();
  }
  yield put(
    yield setActiveLayerPoints({
      value: payload.fields.object_type,
      isActive: true
    })
  );
}
function* updateMarker({ payload }) {
  const request = putMarker;
  const { updateMarkerSuccess: setSuccess, updateMarkerError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* deleteMarker({ payload }) {
  const request = removeMarker;
  const { deleteMarkerSuccess: setSuccess, deleteMarkerError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchProjectRequest, fetchProject);
  yield takeLatest(actions.startInferenceRequest, startInference);
  yield takeLatest(
    actions.startInferenceWithFileRequest,
    startInferenceWithFile
  );
  yield takeLatest(actions.fetchAriesRequest, fetchAries);
  yield takeLatest(actions.fetchMarkersRequest, fetchMarkers);
  yield takeLatest(actions.createLayerRequest, createLayer);
  yield takeLatest(actions.fetchPolygonsRequest, fetchPolygons);
  yield takeLatest(actions.fetchMarkerDetailsRequest, fetchMarkerDetails);
  yield takeLatest(actions.createMarkerRequest, createMarker);
  yield takeLatest(actions.updateMarkerRequest, updateMarker);
  yield takeLatest(actions.deleteMarkerRequest, deleteMarker);
  yield takeLatest(actions.setLayerIsActive, handleSetLayerIsActive);
  yield takeEvery(
    actions.checkInferenceStatusRequest,
    watchCheckInferenceStatus
  );
}
